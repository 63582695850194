










































































































































































































































































import { mdiMicrosoftWindows, mdiApple } from '@mdi/js';
import _ from 'lodash';
import Vue from 'vue';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import LoginCard from '@/components/specific/LoginCard.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import store from '@/store';
import { DomainAuth, DomainAuthMapper } from '@/store/modules/domain/auth';
import { DomainStrapiInfo } from '@/store/modules/domainStrapi/info';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIGroupMapper } from '@/store/modules/ui/group';
import { UIMemberFilterMapper } from '@/store/modules/ui/memberFilter';
import { UINotificationMapper } from '@/store/modules/ui/notification';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';
import type { UserAttributes } from '@/store/modules/domain/auth';
import type { Greeting } from '@/store/modules/ui/common';
import type { Group } from '@/store/modules/ui/group';
import type { Workspace } from '@/store/modules/ui/workspace';

const AuthService = ServiceFactory.get('auth');
const GroupService = ServiceFactory.get('group');
const UserService = ServiceFactory.get('user');
const WorkspaceService = ServiceFactory.get('workspace');

export default Vue.extend({
  name: 'LoginPage',

  components: {
    LoginCard,
  },

  data() {
    return {
      // 表示順序で記述
      /* eslint-disable vue/sort-keys */
      workspaceImg: '',
      workspaceName: '',
      loginParams: {
        email: '',
        password: '',
        workspaceId: '',
      },
      // ワークスペース情報表示切り替え
      showWS: false,
      // パスワード表示切り替え
      showPW: false,
      // エラーメッセージ表示切り替え
      showErrorMessage: false,
      errorMessage: '',
      // showedDialog: false,
      showedDialog: {
        anotherTabAuth: false,
        scagent: false,
      },
      checkboxHidden: false,
      icons: {
        mdiMicrosoftWindows,
        mdiApple,
      },
      downloadedMS: false,
      downloadedApple: false,
      /* eslint-enable vue/sort-keys */
    };
  },

  computed: {
    ...UIWorkspaceMapper.mapState(['workspace']),
    ...UICommonMapper.mapState(['showedGreetingPopup']),
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UINotificationMapper.mapGetters(['webPushToken']),

    macDlUrl() {
      return settings.agent.url.mac;
    },
    windowsDlUrl() {
      return settings.agent.url.win;
    },
  },

  // ログイン画面遷移時に不要になった情報をクリアする
  async beforeRouteEnter(to, from, next) {
    if (to.name === 'Login') {
      const domainAuthContext = DomainAuth.context(store);
      const domainStrapiInfoContext = DomainStrapiInfo.context(store);
      domainAuthContext.actions.clearToken();
      domainAuthContext.actions.clearUserAttributes();
      domainStrapiInfoContext.actions.clearInfos();
      next();
    }
  },

  created() {
    if (this.workspace.subDomain) {
      // ワークスペース情報をセット
      // workspaceIdは非表示/workspaceNameとimage(未設定の場合なし)は表示
      this.loginParams.workspaceId = this.workspace.workspaceId;
      this.workspaceName = this.workspace.workspaceName;
      this.workspaceImg = this.workspace.image;
      this.showWS = true;
    }
    this.clearDomain();
    this.initializeScreen();
    // 別タブログイン検知
    if (this.$route.query.another_tab_auth === 'true') {
      this.showedDialog.anotherTabAuth = true;
    }
  },

  methods: {
    ...DomainAuthMapper.mapActions(['clearDomain', 'clearToken', 'clearUserAttributes', 'setDomain', 'setToken', 'setUserAttributes']),
    ...UICommonMapper.mapActions([
      'clearEditGroupId',
      'clearGreeting',
      'clearMemberCircleUserId',
      'clearMessage',
      'clearPopupUserId',
      'clearProfileUserId',
      'hideAllPopup',
      'setGreeting',
      'setNavigating',
      'setMessage',
      'showGreetingPopup',
    ]),
    ...UIWorkspaceMapper.mapActions(['setWorkspace']),
    ...UIGroupMapper.mapActions(['setGroupList']),
    ...UIMemberFilterMapper.mapActions(['clearFiltered']),

    initializeScreen() {
      this.clearEditGroupId();
      this.clearGreeting();
      this.clearMessage();
      this.clearPopupUserId();
      this.clearMemberCircleUserId();
      this.clearProfileUserId();
      this.clearFiltered();
      this.hideAllPopup();
    },

    async login() {
      const self = this;
      // 最初にsessionStorage,localStorageの情報をすべて削除
      self.clearToken();
      self.clearDomain();
      self.clearUserAttributes();
      // WebSocket用にトークンをCookieに保存する前に、既に保存されていた場合、削除（Webhookが届かない原因となりうるため。）
      if (self.$cookies.get('authToken')) {
        self.$cookies.remove('authToken');
      }

      self.$v.$touch();

      if (self.showErrorMessage) {
        self.showErrorMessage = false;
      }

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.setNavigating({ navigating: true });

      try {
        const responseAuth = await AuthService.login({
          loginId: self.loginParams.email,
          password: self.loginParams.password,
          workspaceId: self.loginParams.workspaceId,
        });

        // トークンをストア
        self.setToken({
          token: _.get(responseAuth, 'token'),
          tokenType: _.get(responseAuth, 'tokenType'),
        });

        // WebSocket用にトークンをCookie保存
        self.$cookies.set('authToken', _.get(responseAuth, 'token'), {
          domain: settings.webSocket.cookieDomain,
        });

        // LocalStorageのloginDateを更新
        const now = new Date().getTime();
        localStorage.setItem('loginDate', JSON.stringify({ date: now }));

        // 現在のドメインを保存（変更されたらログインへ強制移動のため）
        const urlObj = new URL(window.location.href);
        const domain = urlObj.hostname;
        self.setDomain({ domain });

        // 必要情報をまとめて取得
        const userId = _.get(responseAuth, 'userId');
        const workspaceId = _.get(responseAuth, 'workspaceId');
        const procedures = [
          UserService.getUser(workspaceId, userId),
          WorkspaceService.getWorkspace(workspaceId),
          GroupService.getGroupList(workspaceId, userId),
          // TODO ここでグリーティングも取得する
        ];

        const [responseUser, responseWorkspace, responseGroup] = await Promise.all(procedures);

        // ユーザー情報をストアにセット
        const attr: UserAttributes = {
          // 型定義の順序で記述
          /* eslint-disable vue/sort-keys */
          workspaceId,
          userId,
          adminLevel: _.get(responseUser, 'adminLevel'),
          email: _.get(responseUser, 'email'),
          google: _.get(responseUser, 'google.resourceId'),
          userName: _.get(responseUser, 'userName'),
          kana: _.get(responseUser, 'kana'),
          image: _.get(responseUser, 'image'),
          departments: _.get(responseUser, 'departments', []),
          position: _.get(responseUser, 'position'),
          projects: _.get(responseUser, 'projects', []),
          phoneNo: _.get(responseUser, 'phoneNo'),
          voiceXs: _.get(responseUser, 'voiceXs', []),
          timezone: _.get(responseUser, 'timezone', settings.defaultTimezone),
          status: _.get(responseUser, 'status'),
          emotion: _.get(responseUser, 'emotion'),
          place: _.get(responseUser, 'place'),
          device: _.get(responseUser, 'device'),
          currentApp: _.get(responseUser, 'currentApp'),
          chatUnread: _.get(responseUser, 'chatUnread'),
          regDate: _.get(responseUser, 'regDate'),
          updDate: _.get(responseUser, 'updDate'),
          webPushToken: _.get(responseUser, 'webPushToken'),
          webPushEnabled: _.get(responseUser, 'webPushEnabled'),
          /* eslint-enable vue/sort-keys */
        };
        self.setUserAttributes(attr);
        self.$$dayjs.tz.setDefault(attr.timezone);

        // ワークスペース情報をストアにセット
        const workspace: Workspace = {
          atdBorderTime: _.get(responseWorkspace, 'atdBorderTime'),
          atdLimitTime: _.get(responseWorkspace, 'atdLimitTime'),
          email: _.get(responseWorkspace, 'email'),
          image: _.get(responseWorkspace, 'image'),
          subDomain: _.get(responseWorkspace, 'subDomain'),
          tel: _.get(responseWorkspace, 'tel'),
          voiceXDID: _.get(responseWorkspace, 'voiceXDID'),
          voiceXTenant: _.get(responseWorkspace, 'voiceXTenant'),
          voiceXToken: _.get(responseWorkspace, 'voiceXToken'),
          workspaceId: _.get(responseWorkspace, 'workspaceId'),
          workspaceName: _.get(responseWorkspace, 'workspaceName'),
        };
        self.setWorkspace(workspace);

        // グループ一覧情報をストアにセット
        const groupList: Group[] = responseGroup as Group[];
        const sorted = self._.sortBy(groupList, ['groupName']);
        self.setGroupList({ groups: sorted });

        // グリーティング情報をストアにセット
        // const greeting: Greeting = {
        //   imageUrls: [
        //     'https://anima-uploads.s3.amazonaws.com/projects/6268d4e4aa9f8240cd9619ba/releases/633a94ce48220f34154eb017/img/left@1x.svg',
        //   ],
        //   texts: [
        //     'おはようございます！\n今週もあと2日ですね。\n今日も1日頑張りましょう！\nあいうえおかきくけこあいうえ',
        //   ],
        // };
        // self.setGreeting(greeting);
        self.showGreetingPopup();

        const timestamp = sessionStorage.getItem('downloadGuidanceHide') || '';
        const savedTime = Number(timestamp); // 保存されているタイムスタンプ
        const nowTime = new Date().getTime(); // 現在時刻のタイムスタンプ
        const diffTime = (nowTime - savedTime) / 1000;
        const timeLimit = 60 * 60 * 24 * 30; // 非表示30日で設定

        // 開発初期作成のユーザーにagentsがない場合があるため下のように設定
        const isAppInstalled = responseUser.agents ? responseUser.agents[0].deviceId : '';

        // 通常全てのユーザーがagentsの中にinstallIdを持っており、これをShine Connect Agent 側に登録するとdeviceIdが追加される
        // deviceIdのあるユーザーにはダウンロードのダイアログは表示しない
        // installIdのみまたはagentsがない場合は表示
        if (diffTime > timeLimit && !isAppInstalled) {
          self.setNavigating({ navigating: false });
          self.showedDialog.scagent = true;
          sessionStorage.removeItem('downloadGuidanceHide');
        } else {
          self.$router.push('/');
        }
      } catch (error: any) {
        self.$$log.error(error);
        self.setNavigating({ navigating: false });
        // self.setMessage({ color: 'error', text: error.message });

        switch (error.status) {
          case 401:
            this.showErrorMessage = true;
            this.errorMessage = 'メールアドレスまたはパスワードが一致しません。';
            break;
          default:
            this.showErrorMessage = true;
            this.errorMessage =
              'エラーが発生し、ログインできません。\nこのエラーが繰り返し表示される場合、COMSQUAREまでお問い合わせください。';
            break;
        }
      }
    },
    // 実行順序で記述
    /* eslint-disable vue/sort-keys */
    getMSApp() {
      this.downloadedApple = false;
      this.downloadedMS = true;
    },

    getAppleApp() {
      this.downloadedMS = false;
      this.downloadedApple = true;
    },

    toHome() {
      // 非表示設定ONの場合は一定期間表示しない
      if (this.checkboxHidden) {
        sessionStorage.downloadGuidanceHide = JSON.stringify(new Date().getTime());
      }
      // ホームへ
      this.$router.push('/');
    },

    toLogin() {
      this.showedDialog.anotherTabAuth = false;
    },
    /* eslint-enable vue/sort-keys */
  },
  validations() {
    return {
      loginParams: {
        // 表示順序で記述
        /* eslint-disable vue/sort-keys */
        workspaceId: {
          required,
          maxLength: maxLength(128),
        },
        email: {
          required,
          email,
          maxLength: maxLength(128),
        },
        password: {
          required,
        },
        /* eslint-enable vue/sort-keys */
      },
    };
  },
});
